@each $spacing-suffix, $spacing-value in $spacing-map {
  @each $rule in margin, padding {
    @each $location-suffix in $location-suffixes {
      [#{$prefix}~='#{$rule}#{$location-suffix}#{$spacing-suffix}'] {
        #{$rule}#{$location-suffix}: $spacing-value !important;
      }
    }
  }
}

main > header,
main > section,
main > div {
  padding: $spacing-unit * 9 0;
}

#home > div {
  padding: $spacing-unit * 9 0;
  @include media-query($md-break) {
    padding: $spacing-unit * 15.5 0;
  }
}

article > header,
article > section,
article > div,
div[bp="post-container"] > section {
  padding: $spacing-unit * 9 0;
  @include media-query($md-break) {
    padding: $spacing-unit * 15.5 0;
  }
  
}

.pad-top {
  padding: $spacing-unit * 9 0 0 0;
  @include media-query($md-break) {
    padding: $spacing-unit * 15.5 0 0 0;
  }
}

div[bp*="container"] .row,
div[bp*="post-container"] .row {
  margin-bottom: $spacing-unit * 6;
  &:last-child{
    margin-bottom: 0;
  }
}

.lg-gap {
  @include media-query($lg-break) {
    column-gap: $spacing-unit * 7;
  }
}
