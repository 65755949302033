/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
  font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $gray-0;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  word-wrap: normal;
}

em {
  font-style: italic;
}

ol, ul {
  & li {
    margin-bottom: 16px;
    &:last-child:not(:only-child) {
      margin-bottom: 0;
    }
  }
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
// h1, h2, h3, h4, h5, h6,
// p, blockquote, pre,
// ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit * 2;
}

/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
  &:last-child {
    margin: 0;
  }
}

/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}

/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  @include caption();
}

/**
 * Lists
 */
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


/**
 * Links
 */

main a.link,
footer a.link {
  color: $text-color;
  text-decoration: none;
  background-position-y: -0%;
  background-image: linear-gradient( rgba(255,255,255,0) 50%, $text-color 50%);
  transition:
    background 500ms ease,
    color 500ms ease;
  background-size: 1px;
  background-size: auto 185%;
  &:hover {
    background-position-y: 100%;
    color: $gray-0;
    background-image: linear-gradient( rgba(255,255,255,0) 50%, $blue-600 50%);
  }
}

#github {
  background-image: linear-gradient( rgba(255,255,255,0) 50%, $github 50%);
}

#twitter {
  background-image: linear-gradient( rgba(255,255,255,0) 50%, $twitter 50%);
}

#instagram {
  background-image: linear-gradient( rgba(255,255,255,0) 50%, $instagram 50%);
}

#linkedin {
  background-image: linear-gradient( rgba(255,255,255,0) 50%, $linkedin 50%);
}

#dribbble {
  background-image: linear-gradient( rgba(255,255,255,0) 50%, $dribbble 50%);
}


/**
 * Code formatting
 */
pre,
code {
  border: 1px solid $grey-color-light;
  border-radius: 3px;
  background-color: #eef;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

/**
 * Tables
 */
table {
  margin-bottom: $spacing-unit * 2;
  width: 100%;
  text-align: $table-text-align;
  color: lighten($text-color, 18%);
  border-collapse: collapse;
  border: 1px solid $grey-color-light;
  tr {
    &:nth-child(even) {
      background-color: lighten($grey-color-light, 6%);
    }
  }
  th, td {
    padding: ($spacing-unit) ($spacing-unit * 2);
  }
  th {
    background-color: lighten($grey-color-light, 3%);
    border: 1px solid darken($grey-color-light, 4%);
    border-bottom-color: darken($grey-color-light, 12%);
  }
  td {
    border: 1px solid $grey-color-light;
  }
}

/**
 *  Color Backgrounds
 */
.bg-gray--light {
  background-color: $gray-100;
}

.bg-blue--medium {
  background-color: $blue-600;
}

.bg-ulta--magenta {
  background-color: #E90B5A;
}

.bg-ulta--orange {
  background-color: #F26B1C;
}

/**
 *  Utilities
 */
.inverse {
  color: $gray-0;
}

.push-right {
  margin-left: auto;
}

.push-left {
  margin-right: auto;
}

.push-down {
  margin-top: auto;
}

.center {
  margin-left: auto;
  margin-right: auto;
}