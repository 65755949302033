// Page Content
 .page-content {
  flex: 1;
}

.primary-hero {
  min-height: 324px;
  @include media-query($md-break) {
    min-height: 496px;
  }
  @include media-query($lg-break) {
    min-height: 796px;
  }
}

.secondary-hero {
  min-height: 324px;
    h2 {
      margin-bottom: 8px;
    }
    @include media-query($md-break) {
      min-height: 496px;
    }
    @include media-query($lg-break) {
      min-height: 556px;
    }
}

#home #alert {
  margin-top: 32px;
  margin-bottom: 64px;
}

// Home Page Content

#home .primary-hero {
  // background: url('/assets/img/home-hero.jpg');
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  & .overline {
    margin-bottom: $spacing-unit * 2;
  }
  & .title-2 {
    margin-bottom: $spacing-unit * 3;
  }
  & .body-1 {
    font-size: 1.5rem;
    margin-bottom: 16px;
  }
}

hr {
  height: 1px;
  background-color: $gray-200;
  max-width: $container-width; 
  border: none;
  margin: 0 auto;
}

#home #greeting {
  h1 {
      margin-bottom: 32px;
    }
  p.title-5 {
    font-weight: 300;
    line-height: 1.5;
  }
}

.gap--4x {
  column-gap: 64px;
}

#home #recent-links {
  margin-bottom: 32px;
  @include media-query($md-break) {
    margin-bottom: 0;
  }
  & p {
    margin-bottom: 16px;
    &:last-child:not(:only-child) {
      margin-bottom: 0;
    }
  }
  .btn {
    margin-top: 8px;
    margin-bottom: $spacing-unit * 4;
  }
}


// About Page Content
#about {
  & p {
    font-weight: 300;
    line-height: 1.6;
    margin-bottom: 16px;
    &:last-child:not(:only-child) {
      margin-bottom: 0;
    }
  }

  & .image-effect {
    text-align: center;
    @include media-query($lg-break) {
      text-align: left;
    }
  }

  & .portrait {
    width: 100%;
    max-width: 480px;
    @include box-shadow;
  }
}

main#about > section > div[bp="container"] > h3 {
  margin-bottom: $spacing-unit * 3;
}

main#about section#experience div[bp="grid 3@lg"] {
  margin-bottom: $spacing-unit * 4;
}

#about .card--inset {
  border: 1px solid $blue-200;
}



#experience,
#current-favs {
  p {
    margin-bottom: 16px;
    line-height: 1.25;
  }
  .overline {
    margin-bottom: 8px;
  }
  ul li {
    margin-bottom: 0;
  }
}

// Blog Page Content
#blog .secondary-hero {
  // background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

#blog .card-content {
  h3 {
    margin-bottom: 8px;
    &:last-child:not(:only-child) {
      margin: 0;
    }
  }
  .overline {
    margin-bottom: 8px;
  }
}

.post-list-heading {
  margin-bottom: 24px;
}