// Post Base
.post-default {
  p {
    font-size: unquote("clamp(1rem, 0.6667rem + 1.6667vw, 1.5rem)");
    line-height: 1.75;
  }
  ul {
    list-style-type: disc;
    padding-left: 24px;
    font-size: unquote("clamp(1rem, 0.6667rem + 1.6667vw, 1.5rem)");
    line-height: 1.75;
  }
  ol {
    list-style-type: decimal;
    padding-left: 24px;
    font-size: unquote("clamp(1rem, 0.6667rem + 1.6667vw, 1.5rem)");
    line-height: 1.75;
    margin-bottom: 16px;
    li {
      padding-left: 8px;
    }
  }
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit * 2;
  }
}

.post-meta {
  @include caption();
  color: $gray-600;
}

// Post Header
.post-header {
  h1 {
    margin-bottom: $spacing-unit * 2;
  }
}

// Post Hero Section
.post-hero {
  height: 324px;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  @include media-query($md-break) {
    height: 496px;
  }
  @include media-query($lg-break) {
    height: 796px;
  }
  &#homepage-v1 {
    background-image: url('/assets/img/homepage_v1/hero.jpg');
  }
  &#day-of-code {
    background-image: url('/assets/img/day_of_code/hero.jpg');
  }
  &#cart-checkout {
    background-image: url('/assets/img/cart_checkout/hero.jpg');
  }
  &#search {
    background-image: url('/assets/img/search/hero.jpg');
    @include media-query($lg-break) {
      height: 624px;
    }
  }
  &#homepage-v2 {
    background-image: url('/assets/img/homepage_v2/hero.jpg');
    background-position: top center;
  }
  &#rewards {
    background-image: url('/assets/img/rewards/hero.jpg');
    display: none;
  }
  &#glamlab {
    background-image: url('/assets/img/glamlab/hero.jpg');
  }
}

// Post Deets Section
.project-deets {
  margin-top: 32px;
  object {
    margin-bottom: 24px;
    width: 48px;
    height: 48px;
  }
  & .overline {
    margin-bottom: 4px;
    line-height: 1.5;
  }
  p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 0;
  }
}

// Card Overlapping Image Layout
.card-overlaps-image {
  @include media-query($lg-break){
    display: flex;
  }
  margin-bottom: 128px;
    &:last-child:not(:only-child) {
      margin-bottom: 0;
    }
  & .intrinsic {
    position: relative;
      @include media-query($lg-break){
        width: 50%;
        overflow: visible;
      & .image-wrap {
        width: 140%;
        position: relative !important;
        & img {
          height: auto;
          position: static;
        }
      }
    }
  }
  & .image-card-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 2;
    @include media-query($lg-break){
      width: 50%;
    }
    & .image-card {
      width: 100%;
      background-color: $red-600;
      color: $text-inverse;
      padding: $spacing-unit * 6;
      @include box-shadow-lg;
      & > h2 {
        margin-bottom: $spacing-unit * 2;
      }
    }
  }
  &.image-position-right {
    @include media-query($lg-break){
      flex-direction: row-reverse;
      & .image-wrap {
        left: -40%;
      }
    }
  }
}

// Video Sections
.video-full {
  padding: 0;
  video {
    width: 100% !important;
    height: auto !important;
    &:not(.no-point):hover {
      cursor: pointer;
    }
  }
}

// Image Sections
.image-full {
  padding: 0;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

// Embedded Prototypes (iFrames)


// Results & Feedback
.rating {
  margin-bottom: 16px;
  opacity: 0.5;
}

.avatar {
  width: 124px;
  height: 124px;
  border: 4px solid $gray-50;
  border-radius: 50%;
  box-shadow: inset 0 0 4px rgba(0,0,0,0.20);
  margin-bottom: 16px;
}


// Everyone Can Code Post

// Section at bottom describing my role in event.
#event-role [bp="grid"] {
  @include media-query($md-break) {
    column-gap: 64px;
  }
}
