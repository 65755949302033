html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

[#{$prefix}~='container'] {
  width: 100%;
  margin: 0 auto;
  display: block;
  max-width: $container-width;
  padding: 0 $spacing-unit * 3;
  @include media-query(1200px) {
    padding: 0;
  }
}

[#{$prefix}~='post-container'] {
  width: 100%;
  margin: 0 auto;
  display: block;
  max-width: $post-container-width;
  padding: 0 $spacing-unit * 3;
  @include media-query($lg-break) {
    padding: 0;
  }
}

[#{$prefix}~='image-container'] {
  width: 100%;
  margin: 0 auto;
  display: block;
  max-width: $image-container-width;
  padding: 0 $spacing-unit * 3;
  @include media-query($lg-break) {
    padding: 0;
  }
}