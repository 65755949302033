/**
 * Site header
 */
 .site-header {
  width: 100vw;
  box-shadow: 0 0.5px 0 0 hsla(224, 64%, 33%, 0.2);
  min-height: $spacing-unit * 8;
  @include blur-effect();
  // Positioning context for the mobile navigation icon
  position: sticky;
  top: 0;
  z-index: 100;
  & .branding {
    color: $text-color;
    text-decoration: none;
    letter-spacing: 1px;
    background-position-y: initial;
    background-image: none;
    background-size: auto;
    cursor: pointer;
      & svg {
      margin-right: $spacing-unit * 2;
    }
  }
  & .brand-name {
    color: $text-color;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    &:hover {
      background: none;
    }
  }
}

