footer {
  box-shadow: 0 -0.5px 0 hsla(224, 64%, 33%, 0.1);
  padding: $spacing-unit * 8 0;
  background-color: $gray-50;
}

#social {
  margin-top: $spacing-unit * 8;
  flex-direction: column;
  @include media-query($lg-break) {
    flex-direction: row;
    align-items: flex-end;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    order: -1;
    @include media-query($lg-break) {
      order: 0;
      margin-left: auto;
    }
    li {
      margin: 0 $spacing-unit * 2 0 0;
      padding: 8px 8px 8px 0;
      line-height: 1.5;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
      @include media-query($md-break) {
        margin: 0 $spacing-unit * 2;
        padding: 0;
      }
    }
  }
  p {
    margin-top: 32px;
    @include media-query($lg-break) {
      margin-top: 0;
    }
  }
}