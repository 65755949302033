/**
 *  Cards
 */
 .card {
  border-radius: 8px;
  background-color: $gray-0;
  border: 1px solid $gray-200;
  @include box-shadow();
  & .overline {
    margin-bottom: $spacing-unit;
  }
  img {
    border-radius: 8px 8px 0 0;
  }
  p:last-child:not(:only-child) {
    margin-bottom: 0;
  }
  &-content {
    padding: $spacing-unit * 3 $spacing-unit * 2;
    
  }
  &--inset {
    @extend .card;
    padding: $spacing-unit * 2;  }
  &--hero { 
    @extend .card;
    @include box-shadow-lg();
    padding: $spacing-unit * 6;
  }
}

.card-link {
  text-decoration: none;
  color: $text-color;
  transition: transform ease .5s;
  &:hover {
    transform: translateY(-4px) scale(1.02);
  }
}