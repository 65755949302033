// Prefixes
$prefix: 'bp' !default; // prefix layout attibute

// Grid Variables
$container-width: 1140px !default;
$post-container-width: 800px !default;
$image-container-width: 1024px !default;
$gutter: 16px !default;
$cols: 12 !default;

// Breakpoint Variables
$no-break: 0 !default;
$sm-break: 480px !default;
$md-break: 720px !default;
$lg-break: 960px !default;
$xl-break: 1440px !default;

// Spacing Variables
$xs-spacing: 4px !default;
$sm-spacing: 8px !default;
$md-spacing: 16px !default;
$lg-spacing: 24px !default;

// Size Suffixes
$xs-suffix: '--xs' !default;
$sm-suffix: '--sm' !default;
$md-suffix: '' !default;
$lg-suffix: '--lg' !default;
$none-suffix: '--none' !default;

// Location Suffixes
$no-suffix: '' !default;
$top-suffix: '-top' !default;
$bottom-suffix: '-bottom' !default;
$left-suffix: '-left' !default;
$right-suffix: '-right' !default;

// Lists
$location-suffixes: $no-suffix, $top-suffix, $bottom-suffix, $right-suffix, $left-suffix;

// Maps
$spacing-map: ($xs-suffix: $xs-spacing, $sm-suffix: $sm-spacing, $md-suffix: $md-spacing, $lg-suffix: $lg-spacing, $none-suffix: 0);
