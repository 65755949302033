.alert {
  padding: $spacing-unit * 2;
  border-radius: 2px;
  p:first-child {
    margin-bottom: $spacing-unit * 2;
    line-height: 1;
  }
  &--general {
    @extend .alert;
    background-color: $blue-200;
  }
}

