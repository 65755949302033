// Media Query Mixin
@mixin media-query($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin border-bottom($color: $red-600) {
  &::after {
    display: block;
    content: "";
    width: 100vw;
    height: 4px;
    background-color: $color;
    position: absolute;
    bottom: -4px;
  }
}

// Blur Effect Mixin
@mixin blur-effect($opacity: 0.8) {
  background-color: hsla(0, 0%, 100%, $opacity);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
          backdrop-filter: saturate(180%) blur(20px);
  @content;
}

// Box Shadow
@mixin box-shadow {
  box-shadow:
  0 0.3px 0.7px -16px rgba(30, 58, 138, 0.08),
  0 0.7px 1.6px -16px rgba(30, 58, 138, 0.09),
  0 1.3px 3px -16px rgba(30, 58, 138, 0.097),
  0 2.2px 5.4px -16px rgba(30, 58, 138, 0.1),
  0 4.2px 10px -16px rgba(30, 58, 138, 0.13),
  0 10px 24px -16px rgba(30, 58, 138, 0.15);
}

@mixin box-shadow-lg {
  box-shadow:
  0 1.8px 2.2px rgba(30, 58, 138, 0.011),
  0 4.3px 5.3px rgba(30, 58, 138, 0.016),
  0 8px 10px rgba(30, 58, 138, 0.02),
  0 14.3px 17.9px rgba(30, 58, 138, 0.024),
  0 26.7px 33.4px rgba(30, 58, 138, 0.029),
  0 64px 80px rgba(30, 58, 138, 0.04)
;
}

@mixin box-shadow-lg-inverse {
  box-shadow:
  0 1.8px 2.2px rgba(255,255,255, 0.1),
  0 4.3px 5.3px rgba(255,255,255, 0.16),
  0 8px 10px rgba(255,255,255, 0.2),
  0 14.3px 17.9px rgba(255,255,255, 0.24),
  0 26.7px 33.4px rgba(255,255,255, 0.29),
  0 64px 80px rgba(255,255,255, 0.4)
;
}

// Text Crop Mixin
@mixin text-crop($line-height: 1.5, $top-adjustment: 0px, $bottom-adjustment: 0px) {
  // Configured in Step 1
  $top-crop: 6;
  $bottom-crop: 5;
  $crop-font-size: 16;
  $crop-line-height: 1.5;

  // Apply values to calculate em-based margins that work with any font size
  $dynamic-top-crop: max(($top-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0) / $crop-font-size;
  $dynamic-bottom-crop: max(($bottom-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0) / $crop-font-size;

  // Mixin output
  line-height: $line-height;

  &::before,
  &::after {
      content: '';
      display: block;
      height: 0;
      width: 0;
  }

  &::before {
      margin-bottom: calc(#{$dynamic-top-crop}em + #{$top-adjustment});
  }

  &::after {
      margin-top: calc(-#{$dynamic-bottom-crop}em + #{$bottom-adjustment});
  }
}

// Typography Mixins
@mixin base-title {
  font-weight: 500;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
}

@mixin title-1 {
  @include base-title;
  font-size: unquote("clamp(5rem, 4.3333rem + 3.3333vw, 6rem)");
}

@mixin title-2 {
  @include base-title;
  font-size: unquote("clamp(3.5rem, 3.3333rem + 0.8333vw, 3.75rem)");
  margin-bottom: 8px;
}

@mixin title-3 {
  @include base-title;
  font-size: 3rem;

}

@mixin title-4 {
  @include base-title;
  font-size: 2.5rem;
}

@mixin title-5 {
  @include base-title;
  font-size: 2rem;
}

@mixin title-6 {
  @include base-title;
  font-size: 1.5rem;
}

@mixin subtitle-1 {
  font-size: 1.25rem;
  @include text-crop($line-height: 1.5);
  font-weight: 500;
}

@mixin subtitle-2 {
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 500;
}

@mixin body-1 {
  font-size: 1.25rem;
  line-height: 1.6;
}

@mixin body-2 {
  font-size: 1.125rem;
  line-height: 1.6;
}

@mixin body-3 {
  font-size: 1rem;
  line-height: 1.6;
}

@mixin caption {
  font-size: .875rem;
  letter-spacing: 0.25px;
  line-height: 1.6;
}

@mixin overline {
  font-weight: 700;
  font-size: .75rem;
  line-height: 1;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

@mixin button {
  font-weight: 700;
  font-size: .875rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-decoration: none;
  @include text-crop($line-height: 1);
}