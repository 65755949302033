a.btn,
button.btn,
button {
  display: inline-block;
  @include button;
  margin: $spacing-unit * 2 0;
  padding: $spacing-unit * 2 $spacing-unit * 4;
  background-color: $blue-600;
  border-radius: 2px;
  transition: transform ease .5s,
              background-color ease .3s;
  color: $gray-0;
  &::after {
    @include box-shadow;
    opacity: 0;
    transition: opacity ease-in-out .3s;
    &:hover {
      opacity: 1;
    }
  }
  &:hover {
    transform: translateY(-2px);
    background-color: lighten($blue-600, 5%);
  }
  &:active {
    background-color: $blue-700;
  }
  &:first-of-type:not(:only-child) {
    margin-right: $spacing-unit * 2;
  }
} 