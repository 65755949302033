//  Color - Gray
$gray-0:    #FFF;
$gray-50:   #F8FAFC;
$gray-100:  #F1F5F9;
$gray-200:  #E2E8F0;
$gray-300:  #CBD5E1;
$gray-400:  #94A3B8;
$gray-500:  #64748B;
$gray-600:  #475569;
$gray-700:  #334155;
$gray-800:  #1E293B;
$gray-900:  #0F172A;

//  Color - Red
$red-50:   #FFF1F2;
$red-100:  #FFE4E6;
$red-200:  #FECDD3;
$red-300:  #FDA4AF;
$red-400:  #FB7185;
$red-500:  #F43F5E;
$red-600:  #E11D48;
$red-700:  #BE123C;
$red-800:  #9F1239;
$red-900:  #881337;

//  Color - Blue
$blue-50:   #EFF6FF;
$blue-100:  #DBEAFE;
$blue-200:  #BFDBFE;
$blue-300:  #93C5FD;
$blue-400:  #60A5FA;
$blue-500:  #3B82F6;
$blue-600:  #2563EB;
$blue-700:  #1D4ED8;
$blue-800:  #1E40AF;
$blue-900:  #1E3A8A;

//  Color - Green
$green-50:   #ECFDF5;
$green-100:  #D1FAE5;
$green-200:  #A7F3D0;
$green-300:  #6EE7B7;
$green-400:  #34D399;
$green-500:  #10B981;
$green-600:  #059669;
$green-700:  #047857;
$green-800:  #065F46;
$green-900:  #064E3B;

// Color - Brands
$github:    #2EA44F;
$twitter:   #1DA1F2;
$instagram: #833AB4;
$linkedin:  #0077B5;
$dribbble:   #EA4C89;

// Define defaults for each variable.
$base-font-family: 'IBM Plex Sans Condensed', -apple-system, BlinkMacSystemFont, "Segoe UI",
Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$base-font-size:   16px !default;
$base-font-weight: 300 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     8px !default;

$text-color:       $gray-800 !default;
$text-inverse:     $gray-0 !default;
$background-color: $blue-50 !default;
$brand-color:      $red-600 !default;

$grey-color:       $gray-500 !default;
$grey-color-light: $gray-300 !default;
$grey-color-dark:  $gray-700 !default;

$table-text-align: left !default;

