.site-nav {
  margin-left: auto;
  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  a {
    color: $text-color;
    line-height: $base-line-height;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    padding: $spacing-unit;
    background-color: transparent;
    border-radius: 2px;
    transition:
      background-color 300ms ease,
      color 300ms ease;
    &:hover {
      background-color: $red-600;
      color: $gray-0;
    }
    &:active {
      background-color: $red-700;
    }
    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    position: absolute;
    top: 12px;
    right: $spacing-unit * 2;
    background-color: $gray-0;
    text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 38px;
      height: 38px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 38px;
      height: 38px;
      line-height: 0;
      padding-top: 12px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: $spacing-unit $spacing-unit * 2;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}