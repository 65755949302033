// h1, h2, h3, 
// .title-1, .title-2, .title-3 {
//   margin-bottom: $spacing-unit * 3;
// }

h4, h5, h6,
.title-4, .title-5, .title-6 {
  margin-bottom: $spacing-unit * 2;
}

// .subtitle-1, .subtitle-2,
// p, .body-1, .body-2 {
//   margin-bottom: $spacing-unit * 2;
// }

// .overline, .caption {
//   margin-bottom: $spacing-unit;
// }

.row {
  & .overline {
    margin-bottom: $spacing-unit * 2;
  }
  & h1,h2,h3,h4,h5,h6,
    .title-1, .title-2, .title-3,
    .title-4, .title-5, .title-6 {
      margin: 0 0 $spacing-unit * 3;
    &:last-child:not(:only-child) {
      margin-bottom: 0;
    }
  }
  & p,
    .subtitle-1, .subtitle-2,
    .body-1, .body-2,
    .caption {
    margin-bottom: $spacing-unit * 2;
    &:last-child:not(:only-child) {
      margin-bottom: 0;
    }
  }
}

h1, .title-1 {
  @include title-1();
}

h2, .title-2 {
  @include title-2();
}

h3, .title-3 {
  @include title-3();;
}

h4, .title-4 {
  @include title-4();
}

h5, .title-5 {
  @include title-5();
}

h6, .title-6 {
  @include title-6();
}

.subtitle-1 {
  @include subtitle-1();
}

.subtitle-2 {
  @include subtitle-2();
}

p,
.body-1 {
  @include body-1();
}
 
.body-2 {
  @include body-2();
}

.caption {
  @include caption();
}

.overline {
  @include overline();
}

time {
  display: block;
}